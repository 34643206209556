pre {
	overflow-x: auto;
	border: 1px solid $gray-1;
	border-radius: 2px;
	tab-size: 2;
	width: 111%;
	margin-left: -11%;
	padding-left: 9%;
	padding-right: 2%;
	background: #29292e;

	code {
		background-color: transparent;
	}
}

%code-font {
	font-family: $code-font-family;
	font-size: $code-font-size;
	line-height: $code-line-height;
}

code {
	@extend %code-font;
	padding: .25em .5em;
	color: #bf616a;
	background-color: $code-background-color;
	border-radius: 3px;
}

// highlight.js
.hljs {
	font-size: $code-fence-font-size;
	font-weight: 300;
	white-space: pre;
	border: 1px solid $gray-3;
	border-radius: 4px;
}
